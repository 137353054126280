import {TestData} from "../../../models/DatabaseDataTypes";
import {BaseEventsService, BasicEventDetails, BasicEventsList} from "./baseEventsService";

interface InAppNotificationEvent extends BasicEventDetails {
    details: [{
        type: "normal" | "error",
        iconHTML: string,
        html: string,
        autoDismiss: boolean
    }]
}

interface EventDetails extends BasicEventsList {
    "onReportBuilt": BasicReportEvent,
    "onReportUploaded": BasicReportEvent,
    "onReportUploading": BasicReportEvent,
    "onReportDownloaded": BasicReportEvent,
    "onReportDownloading": BasicReportEvent,
    "onReportModified": BasicReportEvent,
    "onReportDeleted": BasicReportEvent,
    "onReportSyncComplete": {details: []},
    "onInAppNotification": InAppNotificationEvent,

    "onFarmBuilt": {details: []}
    "onFarmUploading": {details: []}
    "onFarmUploaded": {details: []}
    "onFarmDownloaded": {details: []}
    "onFarmDownloading": {details: []}
    "onFarmModified": {details: []}
    "onFarmDeleted": {details: []}
    "onFarmSyncComplete": {details: []}

    "onIndexedDBModified": BasicIDBEvent
    "onIndexedDBDeleted": BasicIDBEvent
    "onTokenExpired": {details: []}
}

export type GLOBAL_EVENTS = keyof EventDetails

export interface BasicReportEvent extends BasicEventDetails {
    details: [TestData & {
        new_id?: string
    }]
}

export interface BasicIDBEvent<T = any> extends BasicEventDetails {
    details: [{table: string, data?: T, id?: string}]
}

export class GlobalEventsServiceClass extends BaseEventsService<null, EventDetails>{
    channelName = "global_events"
}


export const GlobalEventsService = new GlobalEventsServiceClass()